import { adjustProtocol } from '@repo/utils';

export function getAppConfig() {
  const {
    VITE_OSS_BASE_URL,
    VITE_INDIVIDUAL_SHARES,
    VITE_INDIVIDUAL_FABRIC_SHARES,
    VITE_DEFAULT_COMPANY_NAME,
    VITE_URL_PARSE_URL
  } = import.meta.env;

  return {
    OSS_BASE_URL: adjustProtocol(VITE_OSS_BASE_URL),
    INDIVIDUAL_SHARES: VITE_INDIVIDUAL_SHARES,
    INDIVIDUAL_FABRIC_SHARES: VITE_INDIVIDUAL_FABRIC_SHARES,
    DEFAULT_COMPANY_NAME: VITE_DEFAULT_COMPANY_NAME,
    URL_PARSE_URL: VITE_URL_PARSE_URL
  };
}