import { joinUrl, setWindowTitle, trimSlashes } from '@repo/utils';
import { getAppConfig } from '@/utils';
import { computed, watch } from 'vue';
import { useParamStore } from './param';
import type { MetadataProperty } from '@repo/style3d';
import { useRelationStore } from '@repo/stores';
import { defineStore } from '@repo/stores';

interface GroupedData {
  [key: string]: {
    name: string;
    value: string;
  }[];
}

const { OSS_BASE_URL, INDIVIDUAL_SHARES, DEFAULT_COMPANY_NAME } = getAppConfig();
const baseUrl = `${OSS_BASE_URL}${INDIVIDUAL_SHARES}`;

export const useShareStore = defineStore('share', () => {

  const paramStore = useParamStore();

  const relationStore = useRelationStore();

  const property = computed(() => {
    const groupedData = relationStore.relation?.metadata?.property.reduce((acc: GroupedData, property: MetadataProperty) => {
      const title = property.title || 'default';
      if (!acc[title]) {
        acc[title] = [];
      }
      acc[title] = acc[title]!.concat(property.data);
      return acc;
    }, {});

    // 暂时未做分组
    return groupedData?.default;
  });

  const currentPathId = computed(() => relationStore.relation?.pathId || '');

  const prevLinkUri = computed(() => relationStore.relation?.linkUri?.prevLinkUri.map((item) => ({
    uri: trimSlashes(item.uri),
    thumb: joinUrl(baseUrl, item.thumb)
  })) || []);
  const nextLinkUri = computed(() => relationStore.relation?.linkUri?.nextLinkUri.map((item) => ({
    uri: trimSlashes(item.uri),
    thumb: joinUrl(baseUrl, item.thumb)
  })) || []);

  const carouselList = computed(() => {
    if (!relationStore.relation?.linkUri) {
      return [];
    }
    if (!prevLinkUri.value.length && !nextLinkUri.value.length) {
      return [];
    }
    const curr = relationStore.relation?.linkUri.curUri;
    return [...prevLinkUri.value, {
      uri: trimSlashes(curr!.uri),
      thumb: joinUrl(baseUrl, curr!.thumb)
    }, ...nextLinkUri.value];
  });

  const nextPathId = computed(() => {
    return nextLinkUri.value[0]?.uri;
  });

  const prevPathId = computed(() => {
    return prevLinkUri.value[0]?.uri;
  });

  const logoUrl = computed(() => relationStore.relation?.metadata?.logo && joinUrl(baseUrl, paramStore.scoId, relationStore.relation?.metadata.logo));

  watch(() => relationStore.relation?.companyName, (val) => {
    setWindowTitle(val || DEFAULT_COMPANY_NAME);
  });

  return {
    currentPathId,
    property,
    carouselList,
    nextPathId,
    prevPathId,
    logoUrl
  };
});
