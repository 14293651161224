<script lang="ts" setup>
import { NButton, NDrawer, NDrawerContent, NSpace, NTooltip } from '@likui628/naive-ui';
import { $t } from '@repo/locales';
import Property from './components/Property.vue';
import { useDrawer } from './composables/useDrawer';

const { maskClosable, current, showDrawer, width, activate } = useDrawer();
</script>
<template>
  <div class="toolbar-container">
    <NSpace vertical>
      <NTooltip placement="left" trigger="hover" :show-arrow="false">
        <template #trigger>
          <NButton size="small" color="#31363B" @click="activate('property')">
            <span class="button-12 icon-info" />
          </NButton>
        </template>
        <span>{{ $t('toolbar.tooltip.property') }}</span>
      </NTooltip>
    </NSpace>
    <NDrawer
      :show="showDrawer"
      :mask-closable="maskClosable"
      :show-mask="false" placement="right"
      :width="width"
      style="margin-right:45px;"
    >
      <NDrawerContent>
        <Property v-if="current === 'property'" />
      </NDrawerContent>
    </NDrawer>
  </div>
</template>
<style scoped>
.toolbar-container {
  z-index: 2001;
  position: absolute;
  right: 5px;
  top: 8px;
}
</style>