import type { RouteRecordRaw } from 'vue-router';
import { DefaultPage } from '../views/default';

export const routes: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '/',
    redirect: '/default',
    children: [
      {
        name: 'project',
        path: '/:id',
        alias: '/project/:id',
        component: DefaultPage
      },
      {
        name: 'fabric',
        path: '/fabric/:id',
        component: DefaultPage
      },
      {
        name: 'style3d',
        path: '/style3d/:id',
        component: DefaultPage
      }
    ]
  }
];
