export function loadWeixinSdk() {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = '//res.wx.qq.com/open/js/jweixin-1.6.0.js';
    script.onload = () => {
      console.log('Primary Weixin JS SDK loaded');
      resolve();
    };
    script.onerror = () => {
      console.log('Primary Weixin JS SDK failed to load, trying backup...');
      const backupScript = document.createElement('script');
      backupScript.src = '//res2.wx.qq.com/open/js/jweixin-1.6.0.js';
      backupScript.onload = () => {
        console.log('Backup Weixin JS SDK loaded');
        resolve();
      };
      backupScript.onerror = reject;
      document.head.appendChild(backupScript);
    };
    document.head.appendChild(script);
  });
}
