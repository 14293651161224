import axios from 'axios';
import { joinUrl } from '@repo/utils';
import { getAppConfig } from '@/utils';

interface WxSignature {
  'nonceStr': string;
  'timestamp': string;
  'url': string;
  'signature': string;
}

export async function getWxSignatureApi(url: string): Promise<WxSignature> {
  const { URL_PARSE_URL } = getAppConfig();
  const resp = await axios.get(joinUrl(URL_PARSE_URL, `/wx/ticket?url=${encodeURIComponent(url)}`));
  return resp.data as WxSignature;
}