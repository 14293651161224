import { getAppConfig } from '@/utils/env';
import { defineStore, storeToRefs } from '@repo/stores';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { until } from '@vueuse/core';
import { type FabricShareInfo, type Relation, useStyle3d, mergeShareFabricInfo } from '@repo/style3d';
import axios, { type AxiosResponse } from 'axios';
import { joinUrl } from '@repo/utils';
import { useLoading } from '@repo/components';
import { useStyle3dStore, useRelationStore } from '@repo/stores';

const { OSS_BASE_URL, INDIVIDUAL_SHARES, INDIVIDUAL_FABRIC_SHARES } = getAppConfig();
const projectRootPath = `${OSS_BASE_URL}${INDIVIDUAL_SHARES}`;
const fabricRootPath = `${OSS_BASE_URL}${INDIVIDUAL_FABRIC_SHARES}`;

export const useParamStore = defineStore('param', () => {
  const loadingSpin = useLoading();

  const rootPath = computed(() => {
    return joinUrl(projectRootPath, scoId.value);
  });

  const scoId = ref<string>('');
  const fabricId = ref<string | null>(null);

  const { loadSco, loadFabric, resetCameraViewState } = useStyle3d();
  const style3dStore = useStyle3dStore();
  const { loaded: sdkLoaded, ready: sdkReady } = storeToRefs(style3dStore);

  const relationStore = useRelationStore();

  async function loadScoResource() {
    console.time('loadScoResource');
    await until(sdkLoaded).toBe(true);

    await loadSco(joinUrl(rootPath.value, '/sco/sco.json'));
    console.timeEnd('loadScoResource');
  }

  async function loadFabricResource() {
    console.time('loadFabricResource');
    await until(sdkReady).toBe(true);

    const jsonResult: AxiosResponse<Relation> = await axios.get(joinUrl(rootPath.value, '/relation.json'));
    const json = jsonResult.data;
    let shapeList = json.shape;
    let fabricRoot = joinUrl(projectRootPath, scoId.value);

    if (fabricId.value) {
      fabricRoot = joinUrl(fabricRootPath, fabricId.value);
      const infoResult: AxiosResponse<FabricShareInfo> = await axios.get(joinUrl(fabricRoot, 'info.json'));
      const info = infoResult.data;
      shapeList = mergeShareFabricInfo(json.shape, info);
    }

    relationStore.setRelation({ ...json, shape: shapeList });
    await loadFabric(shapeList, fabricRoot);
    await resetCameraViewState();
    console.timeEnd('loadFabricResource');
  }

  async function reload() {
    loadingSpin.setLoading(true);
    await loadScoResource();
    await loadFabricResource();
    loadingSpin.setLoading(false);
  }

  async function refreshLoadResource() {
    if (route.name === 'fabric') {
      scoId.value = 'fabric_hanger';
      fabricId.value = route.params.id as string;
    } else {
      scoId.value = route.params.id as string;
      fabricId.value = null;
    }

    await reload();
  }

  onMounted(async () => {
    await refreshLoadResource();
  });

  const route = useRoute();
  watch([() => route.name, () => route.params.id], async () => {
    await refreshLoadResource();
  });

  return { scoId, rootPath };
});
