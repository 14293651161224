<script setup lang="ts">
import { useParamStore } from '@/store/param';
import { NCarousel, NCarouselItem } from '@likui628/naive-ui';
import { storeToRefs } from '@repo/stores';
import { useRouter } from 'vue-router';

const props = defineProps<{ carouselList: { thumb: string, uri: string }[] }>();

const paramStore = useParamStore();
const { scoId } = storeToRefs(paramStore);

const router = useRouter();
const onClick = async (scoId: string) => {
  await router.push(`/project/${scoId}`);
};

</script>
<template>
  <div class="share-list-container">
    <NCarousel slides-per-view="auto" :space-between="20" :loop="false" draggable>
      <NCarouselItem v-for="(item) in props.carouselList" :key="item.uri" class="carousel-item"
                     :class="{ active: item.uri === scoId }">
        <img class="carousel-img" :src="item.thumb" @click="onClick(item.uri)" />
      </NCarouselItem>
    </NCarousel>
  </div>
</template>
<style scoped>
.share-list-container {
  padding: 0px 0px 10px;
}

.carousel-item {
  width: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
}

.carousel-item.active {
  transform: scale(1.2);
}

.carousel-img {
  height: 120px;
  width: 100%;
  object-fit: scale-down;
}
</style>
