<script setup lang="ts">
import { NDescriptions, NDescriptionsItem, NEllipsis } from '@likui628/naive-ui';
import { useRelationStore, storeToRefs } from '@repo/stores';

const relationStore = useRelationStore();
const { property } = storeToRefs(relationStore);

</script>
<template>
  <NDescriptions label-placement="left" bordered :column="1" size="small">
    <NDescriptionsItem v-for="item of property" :key="item.name" label-style="min-width:80px;">
      <template #label>
        {{ item.name }}
      </template>
      <NEllipsis style="max-width: 140px">{{ item.value }}</NEllipsis>
    </NDescriptionsItem>
  </NDescriptions>
</template>
<style scoped></style>
