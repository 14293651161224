import { useMediaQuery } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

export function useDrawer() {
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');

  const maskClosable = ref(false);
  const width = ref<number>(240);

  const current = ref('');
  const showDrawer = computed(() => !!current.value);

  const activate = (c: string) => {
    if (c === current.value) {
      current.value = '';
      return;
    }
    current.value = c;
  };

  watch([current, isLargeScreen], ([c, isLargeScreen]) => {
    switch (c) {
      case 'property':
        maskClosable.value = true;
        width.value = 250;
        break;
      default:
        maskClosable.value = false;
        width.value = isLargeScreen ? 250 : 180;
    }
  });

  return {
    maskClosable,
    width,
    showDrawer,
    activate,
    current,
    isLargeScreen
  };
}