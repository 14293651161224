<script setup lang="ts">
import { Logo, Footer, Style3dViewer } from '@repo/components';
import { ShareList, Toolbar } from '@/components';
import { useShareStore } from '@/store/share.ts';
import { storeToRefs } from '@repo/stores';

const shareStore = useShareStore();
const { carouselList, logoUrl } = storeToRefs(shareStore);
</script>

<template>
  <div class="container">
    <div class="main-content">
      <Style3dViewer class="style3d" />
      <Toolbar />
      <Logo class="logo" :logo-url="logoUrl" />
    </div>
    <ShareList :carouselList="carouselList" class="share-list" />
    <Footer />
  </div>
</template>

<style scoped>
.container {
  background-color: #31363B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.main-content {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
}

.qrScan {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  position: absolute;
  top: 0px;
  left: 0px;
}

.share-list {
  width: 100%;
  flex: 0;
  margin: auto auto;
}
</style>
