import { createApp } from 'vue';
import App from '@/App.vue';
import { getUrlKey } from '@repo/utils';
import { setupI18n, type SupportedLanguagesType } from '@repo/locales';
import { router } from '@/router';
import { initStores } from '@repo/stores';

async function bootstrap() {
  const app = createApp(App);

  const locale = getUrlKey('locale') ?? 'zh';
  await setupI18n(app, locale as SupportedLanguagesType);

  initStores(app);

  app.use(router);

  app.mount('#app');
}

export { bootstrap };
